<template>
  <!-- 账户信息 -->
  <el-row style="margin-bottom: 20px" class="firstRow">
    <el-col>
      <el-card>
        <ul class="billList">
          <li>
            <i class="iconfont icon-jinbizhuanchu"></i>
            <div>
              <p class="Text" style="margin: 0">今年历史消耗</p>
              <div class="MainHead">${{ $filters.thousands(total_spent) }}</div>
              <el-tooltip placement="bottom" effect="light">
                <template #content>
                  <span>历史消耗截止到昨天</span>
                </template>
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </div>
          </li>
          <li>
            <i class="iconfont icon-Send-Money"></i>
            <div>
              <p class="Text" style="margin: 0">今年历史打款</p>
              <div class="MainHead">${{ $filters.thousands(total_recharge) }}</div>
            </div>
          </li>
          <li>
            <i class="iconfont icon-jiaofeizhangdan_active"></i>
            <div>
              <p class="Text" style="margin: 0">今年历史账单金额</p>
              <div class="MainHead">${{ $filters.thousands(total_bill) }}</div>
            </div>
          </li>
        </ul>
      </el-card>
    </el-col>
  </el-row>

  <!-- 开户历史 -->
  <el-row>
    <el-col>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span class="SubHead"
              >历史账单
              <el-tooltip placement="bottom" effect="light">
                <template #content>
                  <span>历史账单截止到上月</span>
                </template>
                <i class="el-icon-warning-outline"></i> </el-tooltip
            ></span>
          </div>
        </template>
        <el-form ref="billFormRef" :inline="true" :model="billForm" style="padding-top: 15px">
          <el-form-item label="选择日期" prop="timeRange">
            <el-date-picker
              v-model="billForm.timeRange"
              type="monthrange"
              size="small"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="X"
              style="margin-left: 14px"
            ></el-date-picker>
          </el-form-item>
          <!-- <el-form-item label="业务类型" prop="type">
            <el-select v-model="billForm.type" placeholder="请选择业务类型" size="small">
              <template v-for="(o, index) in optionType" :key="index">
                <el-option :label="o" :value="o"></el-option>
              </template>
            </el-select>
          </el-form-item> -->
          <el-form-item style="margin-left: 14px">
            <el-button type="primary" @click="queryFormBtn" size="small">查询</el-button>
            <el-button @click="resetFormBtn" size="small">重置</el-button>
          </el-form-item>
        </el-form>

        <el-table
          :data="billList"
          style="width: 100%"
          :header-cell-style="{
            background: 'var(--el-table-row-hover-background-color)',
          }"
        >
          <el-table-column property="bill_name" label="主体" />
          <el-table-column property="month" label="月份" />
          <el-table-column property="type" label="业务" />
          <el-table-column label="金额">
            <template #default="{ row }">
              {{ $filters.thousands(row.costs) }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template #default="{ row }">
              <el-button
                type="text"
                size="mini"
                class="LinkText"
                @click="downLoadDetail(row.month)"
                style="margin-right: 10px"
                >下载明细</el-button
              >
              <el-button
                type="text"
                size="mini"
                class="LinkText"
                @click="downLoadInvoice(row.path)"
                v-show="row.path != ''"
                style="margin-left: 0"
                >下载invoice</el-button
              >
            </template>
          </el-table-column>
          <template #empty>
            <img src="@/assets/empty.gif" alt="" style="width: 400px; height: 400px" />
          </template>
        </el-table>

        <!-- 分页器 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.page"
          :page-sizes="[5, 10]"
          :page-size="queryInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          background
        >
        </el-pagination>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import { ElLoading } from 'element-plus'
export default {
  name: 'Bill',
  data() {
    return {
      userInfo: '',
      total_spent: '',
      total_recharge: '',
      total_bill: '',
      billForm: {
        timeRange: '',
        type: '',
      },
      optionType: [],
      billList: [],
      // 表单查询
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 5,
      },
      total: 0,
    }
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo
    this.getBillList()
  },
  methods: {
    async getBillList() {
      const { data: res } = await this.$http.post('cloudfb/bill', {
        token: this.userInfo.token,
        page: this.queryInfo.page,
        pagesize: this.queryInfo.pagesize,
        timeRange: this.billForm.timeRange,
        type: this.billForm.type,
      })
      if (res.code == 300) {
        this.$router.push('/login')
      }
      if (res.code == 100) {
        this.$message.error(`${res.msg}`)
        this.$router.push('/clientInfo')
      }
      this.billList = res.list
      this.total = res.data.total
      this.optionType = res.data.type
      this.total_spent = res.data.spent
      this.total_recharge = res.data.recharge
      this.total_bill = res.data.bill
    },
    queryFormBtn() {
      this.getBillList()
    },
    resetFormBtn() {
      this.$refs.billFormRef.resetFields()
      this.getBillList()
    },
    // 展示条数改变触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getBillList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getBillList()
    },
    downLoadDetail(month) {
      const loading = ElLoading.service({
        spinner: 'el-icon-loading',
        lock: true,
        text: '下载中，请稍后...',
      })
      this.$http({
        // 用axios发送post请求
        method: 'post',
        url: 'cloudfb/bill_export', // 请求地址
        data: {
          token: this.userInfo.token,
          month: month,
        },
        responseType: 'blob', // 表明返回服务器返回的数据类型
      })
        .then((res) => {
          // 处理返回的文件流
          const content = res.data
          const blob = new Blob([content])
          const fileName = '账单明细.xls'
          if ('download' in document.createElement('a')) {
            // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
        .finally(() => {
          loading.close()
        })
    },
    downLoadInvoice(path) {
      window.open(path)
    },
  },
}
</script>

<style lang="less" scoped>
.billList {
  display: flex;
  justify-content: space-evenly;
  li {
    position: relative;
    width: 33%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-right: 1px solid #d9d9d9;
    i.iconfont {
      font-size: 50px;
      color: #2b82ff;
    }
    i.el-icon-warning {
      position: absolute;
      top: 1px;
      right: 22px;
      font-size: 18px;
      color: #909399;
      cursor: pointer;
    }
    div {
      width: 50%;
    }
  }
  li:last-child {
    border: none;
  }
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.firstRow {
  :deep(.el-card__body) {
    padding: 36px 20px;
  }
}
.billList li i.el-icon-warning-outline {
  position: absolute;
  top: 0;
  right: 30px;
  font-size: 18px;
  color: #909399;
  cursor: pointer;
}
i.el-icon-warning-outline {
  cursor: pointer;
}
</style>
